.app-container {
  width: 100vw;
  height: 100vh;
  background: #22353e;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: monospace, Georgia, sans-serif;
}

.text-container {
  color: white;
  font-weight: bold;
  font-family: monospace, sans-serif;

}

.text-container h1 {
  font-size: 26px;
}

.text-container h1 {
  font-size: 22px;
}
